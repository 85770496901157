import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {collapseExpandEnterLeave} from '../_animations/collapseExpandEnterLeave';
import {DateFormGroupComponent} from '../_components/date-form-group.component';
import {PhoneFormGroupComponent} from '../_components/phone-form-group.component';
import {Config} from '../_services/config';
import {EmailValidation} from '../_validation/email-validation';
import {AbstractRegistrationViewComponent} from './abstract-registration-view-component';

@Component({
    selector: 'registration-customer-view',
    templateUrl: './registration-customer-view.component.html',
    animations: [collapseExpandEnterLeave]
})
export class RegistrationCustomerViewComponent extends AbstractRegistrationViewComponent implements OnInit {
    selectableCountries: string[] = [];
    errorDefinitions: any;

    form = this.formBuilder.group({
        email: ['', Validators.compose([Validators.required, EmailValidation.validEmail])],
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        gender: ['', Validators.required],
        company: [''],
        firstAddressLine: ['', Validators.required],
        secondAddressLine: [''],
        postalCode: ['', Validators.required],
        city: ['', Validators.required],
        country: ['', Validators.required],
        isNotDriver: [false],
    });

    @ViewChild('dateOfBirthFormGroup', { static: true }) dateOfBirthFormGroup: DateFormGroupComponent;
    @ViewChild('phoneFormGroup', { static: true }) phoneFormGroup: PhoneFormGroupComponent;

    constructor(
        private formBuilder: FormBuilder,
        private translate: TranslateService,
        private config: Config
    ) {
        super();
    }

    ngOnInit() {
        this.translate.onLangChange.subscribe(() => {
            this.onLocaleChange();
        });

        this.selectableCountries = this.config.get('countryCodes', []);
        this.onLocaleChange();
        this.form.get('country').setValue(this.config.get('defaultCountry'));
    }

    private onLocaleChange() {
        this.errorDefinitions = {};
        this.translate.get('FORM-ERROR').subscribe(() => {
            this.errorDefinitions = {
                email: {
                    "required": this.translate.instant('FORM-ERROR.EMAIL.REQUIRED'),
                    "email": this.translate.instant('FORM-ERROR.EMAIL.INVALID')
                },
                gender: {
                    "required": this.translate.instant('FORM-ERROR.GENDER.REQUIRED')
                },
                firstName: {
                    "required": this.translate.instant('FORM-ERROR.FIRST-NAME.REQUIRED')
                },
                lastName: {
                    "required": this.translate.instant('FORM-ERROR.LAST-NAME.REQUIRED')
                },
                firstAddressLine: {
                    "required": this.translate.instant('FORM-ERROR.FIRST-ADDRESS-LINE.REQUIRED')
                },
                postalCode: {
                    "required": this.translate.instant('FORM-ERROR.POSTAL-CODE.REQUIRED')
                },
                city: {
                    "required": this.translate.instant('FORM-ERROR.CITY.REQUIRED')
                },
            };
        });
    }

    protected onViewActive() {
        this.form.setValue({
            email: this.formData.email || '',
            firstName: this.formData.firstName || '',
            lastName: this.formData.lastName || '',
            gender: this.formData.gender || '',
            company: this.formData.company || '',
            firstAddressLine: this.formData.addressLine || '',
            secondAddressLine: this.formData.secondAddressLine || '',
            postalCode: this.formData.postalCode || '',
            city: this.formData.city || '',
            country: this.formData.country || '',
            isNotDriver: this.formData.isDriver !== undefined ? !this.formData.isDriver : false,
        });

        if (this.phoneFormGroup) {
            this.phoneFormGroup.setValue(this.formData.phoneNumber);
        }
        if (this.dateOfBirthFormGroup) {
            this.dateOfBirthFormGroup.setValue(this.formData.dateOfBirth);
        }
    }

    onFormSubmit() {
        // mark all form controls as dirty to show possible error messages
        for (const formControlName of Object.keys(this.form.controls)) {
            this.form.get(formControlName).markAsDirty();
        }
        this.dateOfBirthFormGroup.markAsDirty();
        this.phoneFormGroup.markAsDirty();

        if (!this.form.valid || !this.dateOfBirthFormGroup.isValid() || !this.phoneFormGroup.isValid()) {
            return;
        }

        this.saveFormData();
        this.goToNextPage();
    }

    saveFormData() {
        this.formData.email = this.form.value.email;
        this.formData.firstName = this.form.value.firstName;
        this.formData.lastName = this.form.value.lastName;
        this.formData.gender = this.form.value.gender;
        if (this.form.value.company) {
            this.formData.company = this.form.value.company;
        }
        this.formData.addressLine = this.form.value.firstAddressLine;
        if (this.form.value.secondAddressLine) {
            this.formData.secondAddressLine = this.form.value.secondAddressLine;
        }
        this.formData.postalCode = this.form.value.postalCode;
        this.formData.city = this.form.value.city;
        this.formData.country = this.form.value.country;
        this.formData.dateOfBirth = this.dateOfBirthFormGroup.getValue();
        this.formData.phoneNumber = this.phoneFormGroup.getValue();

        const isDriverValueChanged = !this.formData.isDriver !== this.form.value.isNotDriver;
        this.formData.isDriver = !this.form.value.isNotDriver;

        if (isDriverValueChanged) {
            // Clear possibly previously filled in driver details
            this.formData.driver = undefined;
        }

        if (this.formData.isDriver) {
            // Copy driver details from contract owner
            this.formData.driver = {
                firstName: this.formData.firstName,
                lastName: this.formData.lastName,
                email: this.formData.email,
                gender: this.formData.gender,
                dateOfBirth: this.formData.dateOfBirth,
                phoneNumber: this.formData.phoneNumber
            };
        }
    }
}
