import {Component, Input} from '@angular/core';
import {collapseExpandEnterLeave} from '../_animations/collapseExpandEnterLeave';
import {collapseExpand} from '../_animations/collapseExpand';

@Component({
    selector: 'loading-block',
    templateUrl: './loading-block.html',
    animations: [collapseExpandEnterLeave, collapseExpand]
})
export class LoadingBlockComponent {
    @Input() loading = false;
}
