import {AbstractControl, ValidatorFn} from '@angular/forms';
import * as moment from 'moment';
import {Moment, MomentFormatSpecification} from 'moment';

const momentJs = (moment as any).default ? (moment as any).default : moment;

export class DateValidation {

    static betweenAndValid = (from: Moment, to: Moment, inclusivity: "()" | "[)" | "(]" | "[]") => {
        return (formControl: AbstractControl): ValidatorFn => {
            let day = formControl.get('day').value,
                month = formControl.get('month').value,
                year = formControl.get('year').value;

            if (!day || day < 1) {
                formControl.get('day').setErrors({
                    required_day: true
                });
                return;
            }

            if (!month || month < 1) {
                formControl.get('month').setErrors({
                    required_month: true
                });
                return;
            }

            if (!year || year < 1) {
                formControl.get('year').setErrors({
                    required_year: true
                });
                return;
            }

            let date: Moment = momentJs(year + '-' + month + '-' + day, 'Y-M-D');

            if (!date.isValid()) {
                let dateParts = ['year', 'month', 'day'];

                // Set an invalid error on the input that is invalid
                // The value of invalidAt will be 0 if year, 1 if month and 2 if day is invalid
                if (date.invalidAt() > -1) {
                    let errors = {};
                    errors['invalid_' + dateParts[date.invalidAt()]] = true;
                    formControl.get(dateParts[date.invalidAt()]).setErrors(errors);
                }
                return;
            }

            if (!date.isBetween(from, to, 'day', inclusivity)) {
                formControl.get('day').setErrors({
                    between: true
                });
                return;
            }

            formControl.get('day').setErrors(null);
            formControl.get('month').setErrors(null);
            formControl.get('year').setErrors(null);
        };
    }

    static matchesFormat = (format: MomentFormatSpecification, allowedEmpty: boolean = false) => {
        return (formControl: AbstractControl) => {

            if (allowedEmpty && !formControl.value) {
                return;
            }

            if (!formControl.value) {
                return {required: true};
            }

            let value = formControl.value;

            if (momentJs.isMoment(value)) {
                // Get initial input value from moment object
                value = value._i;
            }

            if (Array.isArray(value)) {
                // Valid, array is a valid moment input outputted by the datepicker
                return;
            }

            // Otherwise the user inputted a string, validate if it is of the correct format
            value = momentJs(value, format, true);

            if (!value.isValid()) {
                // value doesn't match format
                return {invalid: true};
            }

            // value matches format
        };
     }
}
