import {Component, Input} from '@angular/core';
import {collapseExpandEnterLeave} from '../../_animations/collapseExpandEnterLeave';

@Component({
    selector: 'loading-indicator',
    templateUrl: './loading-indicator.component.html',
    animations: [collapseExpandEnterLeave]
})
export class LoadingIndicatorComponent {
    @Input() loading = false;
    @Input() inline = false;
}
