import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTabGroup} from '@angular/material/tabs';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {RegistrationFormData} from '../_interfaces/registrationFormData';
import {RpcApiService} from '../_services/api/rpc-api.service';
import {Config} from '../_services/config';

@Component({
    templateUrl: './new-customer-registration.component.html'
})
export class NewCustomerRegistrationComponent implements OnInit {
    views = {
        intro: 0,
        customer: 1,
        driver: 2,
        billing: 3,
        overview: 4,
        done: 5,
    };

    formData: RegistrationFormData = {};
    loading = true;

    @ViewChild('viewTabs', { static: true }) viewTabs: MatTabGroup;

    constructor(
        private route: ActivatedRoute,
        private config: Config,
        private rpcApiService: RpcApiService,
        private translate: TranslateService,
        private router: Router
    ) {}

    async ngOnInit() {
        this.formData.brand = this.config.get('monitorlinq.api.brand');

        const email = this.route.snapshot.queryParams['email'];
        if (email) {
            this.formData.email = email;
        }

        const invitationUuid = this.route.snapshot.queryParams['invitation'];
        if (invitationUuid) {
            this.formData.invitationUuid = invitationUuid;
            await this.rpcApiService.executeRequest('GET', 'invitation/' + invitationUuid)
                .then(data => {
                    if (data) {
                        this.formData = Object.assign(this.formData, data);

                        if (this.formData.locale) {
                            this.translate.use(this.formData.locale);
                        }
                    }
                });
        }

        this.loading = false;

        setTimeout(() => {
            this.setActiveView(this.formData.activeView > 0 ? this.formData.activeView : this.views.intro);
        }, 400);
    }

    setActiveView(viewIndex: number) {
        this.viewTabs.selectedIndex = viewIndex;
        window.scrollTo(0, 0);

        this.formData.activeView = viewIndex;

        this.saveFormDataToApi();
    }

    saveFormDataToApi() {
        if (!this.formData) {
            return;
        }

        let postData: any = {
            brand: this.formData.brand,
            data: this.formData
        };

        if (this.formData.invitationUuid) {
            postData.uuid = this.formData.invitationUuid;
        }

        if (this.formData.email) {
            postData.email = this.formData.email;
        }

        this.rpcApiService
            .executeRequest('POST', 'invitation/update', postData)
            .then((response: any) => {
                if (response && response.hasOwnProperty('uuid')) {
                    this.formData.invitationUuid = response.uuid;
                    this.addUuidToRoute();
                }
            })
            .catch(error => {
                console.error('Registration backend denied POST /invitation/update', error);
            });
    }

    private addUuidToRoute() {
        if (!this.formData.invitationUuid) {
            return;
        }

        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {
                invitation: this.formData.invitationUuid
            },
            queryParamsHandling: 'merge'
        })
    }
}
