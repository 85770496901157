import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {AppModule} from './app/app.module';
import {CONFIG} from './environment';

// This still has to be declared
declare var gtag: Function;

enableProdMode();

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

const config = CONFIG;

if (config.google && config.google["tag-manager"].enabled && config.google["tag-manager"]["tracking-id"]) {
    // Embed the external Google Tag Manager JavaScript file in the HTML document HEAD tag.
    let googleTagManagerIncludeScript = document.createElement('script');
    googleTagManagerIncludeScript.async = true;
    googleTagManagerIncludeScript.src = "https://www.googletagmanager.com/gtag/js?id=" + config.google["tag-manager"]["tracking-id"];
    document.head.appendChild(googleTagManagerIncludeScript);

    // Embed the Google Tag Manager script in the HTML document HEAD tag.
    let googleTagManagerScript = document.createElement('script');
    googleTagManagerScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
    `;
    googleTagManagerScript.innerHTML += "gtag('config', '" + config.google["tag-manager"]["tracking-id"] + "');";
    document.head.appendChild(googleTagManagerScript);
}
