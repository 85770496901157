import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

@Injectable()
export class HttpService {
    readonly defaultHeaders = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    };
    private headers: {
        [header: string]: string | string[];
    } = {};

    constructor(
        private http: HttpClient
    ) {
        this.headers = Object.assign(this.headers, this.defaultHeaders);
    }

    public resetHeaders (fullReset: boolean = false) {
        let initialValues = this.headers;

        if (fullReset) {
            initialValues = {};
        }

        this.headers = Object.assign(initialValues, this.defaultHeaders);
    }

    public updateHeader (key: string, value: string) {
        this.headers[key] = value;
    }

    public removeHeader (key: string) {
        delete this.headers[key];
    }

    public getHeaders() {
        return this.headers;
    }

    public get (url: string, params: any = {}): Observable<Object> {
        return this.request('get', url, {}, params);
    }

    public post (url: string, body: any = {}, params: any = {}): Observable<Object> {
        return this.request('post', url, body, params);
    }

    public patch (url: string, body: any = {}, params: any = {}): Observable<Object> {
        return this.request('patch', url, body, params);
    }

    public put (url: string, body: any = {}, params: any = {}): Observable<Object> {
        return this.request('put', url, body, params);
    }

    public delete (url: string, params: any = {}): Observable<Object> {
        return this.request('delete', url, {}, params);
    }

    public request(method: string, url: string, body: any = {}, params: any = {}, overrideOptions: any = {}): Observable<Object> {
        let headers = new HttpHeaders(this.headers);

        let finalOptions = Object.assign({
                headers: headers
            },
            overrideOptions
        );

        url = url.replace('{{host}}', window.location.protocol + '//' + window.location.host);

        if (params && Object.keys(params).length) {
            url += '?' + this.createQueryString(params);
        }

        let requestOptions = finalOptions;

        let observer;

        method = method.toLowerCase();
        if (method === 'get' || method === 'delete') {
            observer = this.http[method](url, requestOptions);
        } else {
            observer = this.http[method](url, body, requestOptions);
        }

        return observer;
    }

    public createQueryString(parameters: any = {}) {
        // create query string from parameters which is accepted by RequestOptions
        return Object.keys(parameters).reduce(
            (acc, cur, i) => {
                // if parameter value is array, reduce them to a string like "parameter[]=value1&parameter[]=value2"
                // otherwise simply use "parameter=value"
                return `${acc}${i > 0 ? '&' : ''}${Array.isArray(parameters[cur])
                    ? parameters[cur].reduce(
                      (arrAcc, arrCur, arrI) => `${arrAcc}${arrI > 0 ? '&' : ''}${cur}[]=${encodeURIComponent(arrCur)}`, ''
                  )
                    : `${cur}=${encodeURIComponent(parameters[cur])}`
                }`;
            }, ''
        );
    }
}
