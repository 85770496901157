import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {FeatureFlagService} from '../_services/feature-flag.service';

@Injectable()
export class FeatureFlagGuard implements CanActivate {
    constructor(
        private router: Router,
        private featureFlagService: FeatureFlagService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
        if (!route.data || !route.data.hasOwnProperty('featureFlags') || route.data['featureFlags'].length === 0) {
            return true;
        }

        return this.isOneOfFeatureFlagsEnabled(route.data['featureFlags']);
    }

    private isOneOfFeatureFlagsEnabled(featureFlags: string[]): boolean {
        let allowedAccess = featureFlags.some((featureFlag) => this.featureFlagService.isEnabled(featureFlag));

        if (allowedAccess) {
            return true;
        }

        this.router.navigate(['/']);
        return false;
    }
}
