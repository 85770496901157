import {ErrorHandler, Injectable} from '@angular/core';
import * as Sentry from '@sentry/browser';
import {brand} from '../../environment';
import {Config} from '../_services/config';
import {VERSION} from '../version';

@Injectable()
export class RavenErrorHandler implements ErrorHandler {
    constructor(
        private config: Config
    ) {
        Sentry.init({
            enabled: this.config.get('sentry.enabled') || false,
            dsn: this.config.get('sentry.dsn'),
        });

        Sentry.configureScope(scope => {
            scope.setTags({
                appVersion: VERSION,
                brand: brand,
            });
        });
    }

    handleError(error: any): void {
        Sentry.captureException(error.originalError || error);
        throw error;
    }
}
