import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {FeatureFlagService} from '../_services/feature-flag.service';

@Directive({
    selector: '[mlIfFeatureFlagEnabled]'
})
export class IfFeatureFlagEnabledDirective {
    private hasView = false;
    private featureFlag: string;

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private featureFlagService: FeatureFlagService
    ) {}

    @Input() set mlIfFeatureFlagEnabled(featureFlag: string) {
        this.featureFlag = featureFlag;
        this.determineView();
    }

    private determineView() {
        if (!this.featureFlag) {
            return;
        }

        if (!this.hasView && this.featureFlagService.isEnabled(this.featureFlag)) {
            // Feature is enabled and view is not visible yet, add element to view
            this.viewContainer.createEmbeddedView(this.templateRef);
            this.hasView = true;
        } else if (this.hasView && !this.featureFlagService.isEnabled(this.featureFlag)) {
            // Feature is disabled and view is still visible, remove element from view
            this.viewContainer.clear();
            this.hasView = false;
        }
    }
}
