import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {interval as observableInterval, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import {collapseExpandEnterLeave} from '../_animations/collapseExpandEnterLeave';
import {SystemService} from '../_services/system.service';

@Component({
    templateUrl: './connection-error-dialog.component.html',
    animations: [collapseExpandEnterLeave]
})
export class ConnectionErrorDialogComponent implements OnInit, OnDestroy {
    secondsRemaining = 30;
    loading = false;
    countdownTimer: Subscription;
    noInternetConnection = false;

    constructor(
        private systemService: SystemService,
        private router: Router,
        public dialogRef: MatDialogRef<ConnectionErrorDialogComponent>
    ) {}

    @HostListener('window:offline', ['$event']) onOffline() {
        this.noInternetConnection = true;
        this.stopTimer();
    }

    @HostListener('window:online', ['$event']) onOnline() {
        this.noInternetConnection = false;
        this.startTimer();
    }

    ngOnInit() {
        this.noInternetConnection = !navigator.onLine;

        if (!this.noInternetConnection) {
            this.startTimer();
        }
    }

    startTimer() {
        this.secondsRemaining = 30;

        // Update timer every second
        this.countdownTimer = observableInterval(1000).pipe(
            map(() => {
                this.secondsRemaining--;

                if (this.secondsRemaining === 0) {
                    this.tryConnection();
                    return;
                }
            }))
            .subscribe();
    }

    stopTimer() {
        if (this.countdownTimer) {
            this.countdownTimer.unsubscribe();
            this.countdownTimer = undefined;
        }
    }

    tryConnection() {
        if (this.loading || this.noInternetConnection) {
            return;
        }

        this.loading = true;
        // Check if we can connect to the API
        this.systemService.heartbeat().then((ok: boolean) => {
            if (ok) {
                this.dialogRef.close();
                // Reload the current route
                this.router.navigate(['/force-redirect'], { queryParams: { redirectTo: this.router.url }});
                return;
            }

            this.loading = false;
            this.secondsRemaining = 30;
        });
    }

    ngOnDestroy() {
        this.stopTimer();
        this.noInternetConnection = false;
    }
}
