import {AbstractControl} from '@angular/forms';

export class EmailValidation {

    static validEmail = (formControl: AbstractControl) => {
        if (!formControl.value) {
            // empty value
            return {required: true};
        }

        let emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/;

        if (!emailRegex.test(formControl.value)) {
            // value doesn't match format
            return {email: true};
        }

        // value matches format
    }
}
