import {LOCAL_CONFIG} from '$config/local.config';
import {Injectable} from '@angular/core';
import * as merge from 'deepmerge';
import {appName, brand, CONFIG} from '../../environment';
import {HttpService} from './http.service';

export function apiConfigInitializer(config: Config) {
    return () => {
        return config.preloadConfig();
    };
}

@Injectable()
export class Config {
    private fullConfig = {};

    constructor(
        private httpService: HttpService
    ) {
        this.fullConfig = CONFIG;
    }

    public static findProperty(configObject: any, search: any, defaultValue: any) {
        if (typeof defaultValue === 'undefined') { defaultValue = null; }
        if (typeof search === 'undefined' || typeof configObject === 'undefined') {
            return defaultValue;
        }
        search = search.split('.');
        for (let i = 0; i < search.length; i++) {
            if (typeof configObject[search[i]] === 'undefined') {
                return defaultValue;
            }
            configObject = configObject[search[i]];
        }
        return configObject;
    }

    preloadConfig(): Promise<any> {
        const requestUrl = this.get('monitorlinq.coreApi.apiUrl')
            + this.get('monitorlinq.coreApi.rpc.endPoint')
            + 'app-settings/' + appName + '.' + brand;

        this.httpService.updateHeader('Authorization', 'ClientId ' + this.get('monitorlinq.coreApi.clientId'));

        return this.httpService.request('GET', requestUrl)
            .toPromise()
            .then(response => {
                this.fullConfig = merge.all(
                    [
                        CONFIG,
                        Config.findProperty(response, 'settings', {}),
                        LOCAL_CONFIG
                    ],
                    {
                        arrayMerge: (d, s, o) => s,
                        // @ts-ignore
                        customMerge: () => (d, s, o) => !Array.isArray(d) && Array.isArray(s) ? d : merge(d, s, o)
                    }
                );
            });
    }

    get(key: any, defaultValue = null) {
        return Config.findProperty(this.fullConfig, key, defaultValue);
    }
}
