import {animate, style, transition, trigger} from '@angular/animations';

export const collapseExpandEnterLeave = trigger('collapseExpandEnterLeave', [
    transition(':enter', [
        style({
            height: 0,
            opacity: 0
        }),
        animate('300ms ease-in-out', style({
            height: '*',
            opacity: 1
        }))
    ]),
    transition(':leave', [
        style({
            height: '*',
            opacity: 1
        }),
        animate('300ms ease-in-out', style({
            opacity: 0,
            height: 0
        }))
    ]),
]);
