import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {ApiErrorResponse} from '../_services/api/abstract-api.service';
import {DialogService} from '../_services/dialog.service';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
    constructor(
        private dialogService: DialogService
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(tap(() => {}, (response: any) => {
            if (response instanceof HttpErrorResponse) {
                if (response.status === 0) {
                    // Server down or no internet connection
                    this.dialogService.openConnectionErrorDialog();
                    return;
                }

                let errorBody = response.error;
                if (errorBody && errorBody.validation_messages) {
                    throw new ApiErrorResponse(response.status, errorBody.validation_messages);
                }

                throw new ApiErrorResponse(response.status);
            }
        }));
    }
}
