import {Injectable} from '@angular/core';
import {Config} from '../config';
import {AbstractApiService} from './abstract-api.service';
import {HttpService} from '../http.service';

@Injectable()
export class RpcApiService extends AbstractApiService {

    rpcApiEndpoint: string;

    constructor(
        public httpService: HttpService,
        protected config: Config
    ) {
        super(config);

        this.rpcApiEndpoint = this.config.get("monitorlinq.api.rpc.endPoint");
    }

    executeRequest(requestMethod: string, endPoint: string, body: any = {}, parameters: any = {}) {
        let requestUrl = this.basePath  + this.rpcApiEndpoint + endPoint;
        return this.httpService.request(requestMethod, requestUrl, body, parameters).toPromise();
    }
}
