import {Component, OnInit} from '@angular/core';
import {PhoneNumberFormat, PhoneNumberUtil} from 'google-libphonenumber';
import {AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {Config} from '../_services/config';

const phoneNumberUtil = PhoneNumberUtil.getInstance();

@Component({
    selector: 'phone-form-group',
    templateUrl: './phone-form-group.html'
})
export class PhoneFormGroupComponent implements OnInit {
    formGroup: FormGroup = this.formBuilder.group(
        {
            country_code: ['', Validators.required],
            phone_number: ['', Validators.required],
        },
        {
            validator: (formControl: AbstractControl): ValidatorFn => {
                let countryCode = formControl.get('country_code').value;
                let phoneNumber = formControl.get('phone_number').value;

                try {
                    if (!countryCode || !phoneNumber) {
                        // Set error
                        formControl.get('phone_number').setErrors({
                            required: true
                        });
                        return;
                    }

                    let phoneNumberValue = phoneNumber.toString(),
                        parsedPhoneNumber = phoneNumberValue.startsWith('+')
                            ? phoneNumberUtil.parse(phoneNumberValue) // International E.164 format
                            : phoneNumberUtil.parse(phoneNumberValue, countryCode); // National format in selected country

                    // Check if number is a valid number and if the selected country matches the number's country
                    if (phoneNumberUtil.isValidNumber(parsedPhoneNumber)
                        && phoneNumberUtil.getRegionCodeForCountryCode(parsedPhoneNumber.getCountryCode()) === countryCode
                    ) {
                        // Clear error
                        formControl.get('phone_number').setErrors(null);
                        return;
                    }
                } catch (e) {
                    // do nothing
                }

                // Set error
                formControl.get('phone_number').setErrors({
                    invalid: true
                });
            }
        }
    );
    errorDefinitions: any = {};
    countries: {
        [countryCode: string]: {
            dial_code: string
        }
    };
    allowedCountryCodes: string[];

    constructor(
        private formBuilder: FormBuilder,
        private translate: TranslateService,
        private config: Config
    ) {}

    ngOnInit() {
        // Populate countries using libphonenumber based on country codes listed in config
        this.allowedCountryCodes = this.config.get('phoneCountryCodes');

        let countries = {};

        phoneNumberUtil.getSupportedRegions().forEach(region => {
            if (this.allowedCountryCodes.indexOf(region) < 0) {
                // Ignore countries that are not available to user according to API
                return;
            }

            countries[region] = {
                dial_code: '+' + phoneNumberUtil.getCountryCodeForRegion(region)
            };
        });

        this.countries = countries;

        this.translate.get('FORM-ERROR').subscribe(() => {
            this.errorDefinitions = {
                "required": this.translate.instant('FORM-ERROR.PHONE.REQUIRED'),
                "invalid": this.translate.instant('FORM-ERROR.PHONE.INVALID'),
            };
        });

        this.formGroup.get('country_code').setValue(this.config.get('defaultCountry'));
    }

    markAsDirty() {
        for (const formControlName of Object.keys(this.formGroup.controls)) {
            this.formGroup.get(formControlName).markAsDirty();
        }
    }

    isValid(): boolean {
        this.markAsDirty();
        return this.formGroup.valid;
    }

    getValue(): string {
        let phoneNumber = phoneNumberUtil.parse(this.formGroup.value.phone_number.toString(), this.formGroup.value.country_code);
        return phoneNumberUtil.format(phoneNumber, PhoneNumberFormat.E164);
    }

    setValue(value: string) {
        if (!value) {
            return;
        }

        let phoneNumber = phoneNumberUtil.parse(value);

        this.formGroup.setValue({
            country_code: phoneNumberUtil.getRegionCodeForCountryCode(phoneNumber.getCountryCode()),
            phone_number: phoneNumber.getNationalNumber()
        });
    }
}
