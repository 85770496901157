import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, ValidationErrors, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {collapseExpandEnterLeave} from '../_animations/collapseExpandEnterLeave';
import {Config} from '../_services/config';
import {AbstractRegistrationViewComponent} from './abstract-registration-view-component';

@Component({
    selector: 'registration-overview-view',
    templateUrl: './registration-overview-view.component.html',
    animations: [collapseExpandEnterLeave]
})
export class RegistrationOverviewViewComponent extends AbstractRegistrationViewComponent implements OnInit {
    billingPeriodProductSkuMapping: {
        [billingPeriod: string]: string
    } = {};

    errorDefinitions: any;

    form = this.formBuilder.group({
        acceptTerms: [false, Validators.requiredTrue],
    });

    billingPeriod: string;
    termsLink: string;

    constructor(
        private config: Config,
        private formBuilder: FormBuilder,
        private translate: TranslateService
    ) {
        super();
        this.billingPeriodProductSkuMapping = this.config.get('billingPeriodProductSkuMapping');
    }

    ngOnInit() {
        this.translate.onLangChange.subscribe(() => {
            this.onLocaleChange();
        });

        this.onLocaleChange();
    }

    private onLocaleChange() {
        this.errorDefinitions = {};
        this.translate.get('FORM-ERROR').subscribe(() => {
            this.errorDefinitions = {
                acceptTerms: {
                    "required": this.translate.instant('FORM-ERROR.TERMS.REQUIRED')
                },
            };

            this.termsLink = '<a href="'
                + this.config.get('termsOfServiceLink')
                + '" target="_blank">'
                + this.translate.instant('LEGAL.TERMS-CONDITIONS')
                + '</a>';
        });
    }

    onFormSubmit() {
        // mark all form controls as dirty to show possible error messages
        for (const formControlName of Object.keys(this.form.controls)) {
            this.form.get(formControlName).markAsDirty();
        }

        if (!this.form.valid) {
            return;
        }

        this.saveFormData();
        this.goToNextPage();
    }

    saveFormData() {
        this.formData.acceptTerms = this.form.value.acceptTerms;
    }

    protected onViewActive() {
        this.billingPeriod = this.mapProductSkuToBillingPeriod(this.formData.productSku);
    }

    private mapProductSkuToBillingPeriod(skuToFind: string) {
        const foundBillingPeriod = Object.entries(this.billingPeriodProductSkuMapping).find(([billingPeriod, productSku]) => {
            return productSku === skuToFind;
        });

        return foundBillingPeriod[0] || undefined;
    }
}
