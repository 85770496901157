import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {collapseExpandEnterLeave} from '../_animations/collapseExpandEnterLeave';
import {Locale} from '../_enums/locale';
import {Config} from '../_services/config';
import {AbstractRegistrationViewComponent} from './abstract-registration-view-component';

@Component({
    selector: 'registration-intro-view',
    templateUrl: './registration-intro-view.component.html',
    animations: [collapseExpandEnterLeave]
})
export class RegistrationIntroViewComponent extends AbstractRegistrationViewComponent implements OnInit {
    @Input() loading = true;
    selectableLocales: Locale[] = [];
    currentLocale: Locale;

    constructor(
        private config: Config,
        private translate: TranslateService
    ) {
        super();
    }

    ngOnInit() {
        const configLocales: string[] = this.config.get('locales.supported', []);
        this.selectableLocales = configLocales.map(Locale.fromString);

    }

    protected onViewActive() {
        this.currentLocale = Locale.fromString(this.translate.currentLang);

        if (this.formData.locale) {
            this.currentLocale = Locale.fromString(this.formData.locale);
        }

        this.formData.language = this.currentLocale.languageCode;

        this.onLocaleChange(this.currentLocale)
    }

    onLocaleChange(locale: Locale) {
        this.currentLocale = locale;
        this.translate.use(locale.toString());
        this.formData.language = locale.languageCode;
        this.formData.locale = locale.toString();
    }
}
