import {LOCAL_CONFIG} from '$config/local.config';
import * as merge from 'deepmerge';

export const appName = 'registration-web';
declare var global;
export const brand = global.brand;

const DEFAULT_CONFIG = {
    "brandName": "ScootSecure",
    "support": {
        "email": "support@scootsecure.nl",
        "phone": "030-3040023",
    },
    "titleSuffix": " - ScootSecure",
    "locales": {
        "default": "nl-NL",
        "supported": ["en-GB", "nl-NL"]
    },
    "defaultCountry": "NL",
    "countryCodes": [
        "BE", "LU", "NL"
    ],
    "phoneCountryCodes": [ // these countries will be displayed in the country dropdown of the phone number forms
        "BE", "DE", "FR", "GB", "LU", "NL"
    ],
    "billingPeriodProductSkuMapping": {
        "MONTHLY": "100100",
        "YEARLY": "100110",
    },
    "billingPeriodSuffix": {
        "monthly": "",
        "yearly": "",
    },
    "termsOfServiceLink": "https://www.scootsecure.nl/algemene-voorwaarden",
    "monitorlinq": {
        "api": {
            "clientId": "registration-web",
            "brand": "scoot-secure-nl",
            "apiUrl": "{{host}}/api/registration",
            "rpc": {
                "endPoint": "/rpc/v1/"
            }
        },
        "coreApi": {
            "clientId": "registration-web",
            "apiUrl": "{{host}}/api/core",
            "rest": {
                "endPoint": "/rest/v3/"
            },
            "rpc": {
                "endPoint": "/rpc/v3/"
            }
        },
    },
    "google": {
        "tag-manager": {
            "enabled": false,
            "tracking-id": "",
            "customer-dimensions": {
                "user_segment": {
                    // Every Custom Dimension has it's own index, which can be found on the Admin page in Google Analytics.
                    "index": 1,
                    // The key is for internal use, the value is the filter option in Google Analytics:
                    "driver": "driver",
                    "other_driver": "other driver"
                }
            }
        }
    },
    "features": {
        "main": {
            "billingBankAccount": true
        }
    },
    "sentry": {
        "enabled": false,
        "dsn": "https://47c638bfeeb64d2d92ae2abd4abd08b4@sentry.io/1483881"
    }
};

export const CONFIG: any = merge.all(
    [DEFAULT_CONFIG, LOCAL_CONFIG],
    {
        arrayMerge: (d, s, o) => s,
        // @ts-ignore
        customMerge: () => (d, s, o) => !Array.isArray(d) && Array.isArray(s) ? d : merge(d, s, o)
    }
);
