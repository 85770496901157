import {Injectable} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs';
import {Config} from './config';

@Injectable()
export class TitleService  {
    private titleSuffix: string;
    private routeTitleSource = new Subject<string>();

    routeTitleChanged$ = this.routeTitleSource.asObservable();

    constructor(
        private pageTitle: Title,
        private config: Config,
        private translate: TranslateService
    ) {
        this.titleSuffix = config.get('titleSuffix');
    }

    setTitle(title: string, withSuffix: boolean = true) {
        // Push new route title to observers
        this.routeTitleSource.next(title);

        if (withSuffix) {
            title += this.titleSuffix;
        }

        // Update page title to title with prefix
        this.pageTitle.setTitle(title);
    }

    setTranslatedTitle(translationKey, withSuffix: boolean = true, translationsParameters: any = {}) {
        this.translate.get(translationKey, translationsParameters)
            .subscribe(translated => {
                this.setTitle(translated, withSuffix);
            });
    }
}
