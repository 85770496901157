import {RouterModule, Routes} from '@angular/router';
import {RegistrationComponent} from './registration/registration.component';
import {NewCustomerRegistrationComponent} from './registration/new-customer-registration.component';

const appRoutes: Routes = [
    {
        path: '',
        component: RegistrationComponent,
        children: [
            { path: '', component: NewCustomerRegistrationComponent},
        ]
    },
];

export const routing = RouterModule.forRoot(appRoutes);
