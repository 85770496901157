import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {collapseExpandEnterLeave} from '../_animations/collapseExpandEnterLeave';
import {DateFormGroupComponent} from '../_components/date-form-group.component';
import {PhoneFormGroupComponent} from '../_components/phone-form-group.component';
import {EmailValidation} from '../_validation/email-validation';
import {AbstractRegistrationViewComponent} from './abstract-registration-view-component';

@Component({
    selector: 'registration-driver-view',
    templateUrl: './registration-driver-view.component.html',
    animations: [collapseExpandEnterLeave]
})
export class RegistrationDriverViewComponent extends AbstractRegistrationViewComponent implements OnInit {
    errorDefinitions: any;

    form = this.formBuilder.group({
        email: ['', Validators.compose([Validators.required, EmailValidation.validEmail])],
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        gender: ['', Validators.required],
    });

    @ViewChild('dateOfBirthFormGroup', { static: true }) dateOfBirthFormGroup: DateFormGroupComponent;
    @ViewChild('phoneFormGroup', { static: true }) phoneFormGroup: PhoneFormGroupComponent;

    constructor(
        private formBuilder: FormBuilder,
        private translate: TranslateService
    ) {
        super();
    }

    ngOnInit() {
        this.translate.onLangChange.subscribe(() => {
            this.onLocaleChange();
        });

        this.onLocaleChange();
    }

    private onLocaleChange() {
        this.errorDefinitions = {};
        this.translate.get('FORM-ERROR').subscribe(() => {
            this.errorDefinitions = {
                email: {
                    "required": this.translate.instant('FORM-ERROR.EMAIL.REQUIRED'),
                    "email": this.translate.instant('FORM-ERROR.EMAIL.INVALID')
                },
                gender: {
                    "required": this.translate.instant('FORM-ERROR.GENDER.REQUIRED')
                },
                firstName: {
                    "required": this.translate.instant('FORM-ERROR.FIRST-NAME.REQUIRED')
                },
                lastName: {
                    "required": this.translate.instant('FORM-ERROR.LAST-NAME.REQUIRED')
                },
            };
        });
    }

    protected onViewActive() {
        if (this.formData.driver) {
            this.form.setValue({
                gender: this.formData.driver.gender,
                firstName: this.formData.driver.firstName,
                lastName: this.formData.driver.lastName,
                email: this.formData.driver.email,
            });
            this.phoneFormGroup.setValue(this.formData.driver.phoneNumber);
            this.dateOfBirthFormGroup.setValue(this.formData.driver.dateOfBirth);
        }
    }

    onFormSubmit() {
        // mark all form controls as dirty to show possible error messages
        for (const formControlName of Object.keys(this.form.controls)) {
            this.form.get(formControlName).markAsDirty();
        }
        this.dateOfBirthFormGroup.markAsDirty();
        this.phoneFormGroup.markAsDirty();

        if (!this.form.valid || !this.dateOfBirthFormGroup.isValid() || !this.phoneFormGroup.isValid()) {
            return;
        }

        this.saveFormData();
        this.goToNextPage();
    }

    saveFormData() {
        this.formData.driver = {
            firstName: this.form.value.firstName,
            lastName: this.form.value.lastName,
            email: this.form.value.email,
            gender: this.form.value.gender,
            dateOfBirth: this.dateOfBirthFormGroup.getValue(),
            phoneNumber: this.phoneFormGroup.getValue()
        };
    }
}
