import {Component} from '@angular/core';
import {collapseExpand} from '../_animations/collapseExpand';
import {LoadingBlockComponent} from './loading-block';

@Component({
    selector: 'loading-block-in-dom',
    templateUrl: './loading-block-in-dom.html',
    animations: [collapseExpand]
})
export class LoadingBlockInDomComponent extends LoadingBlockComponent {}
