import {Pipe, PipeTransform} from '@angular/core';
import {PhoneNumberFormat, PhoneNumberUtil} from 'google-libphonenumber';

const phoneNumberUtil = PhoneNumberUtil.getInstance();

@Pipe({
    name: 'phoneNumber'
})
export class PhoneNumberPipe implements PipeTransform {
    transform(val) {
        if (!val) {
            return val;
        }

        let phoneNumber = phoneNumberUtil.parse(val);

        // Transform E.164 format to country's local format
        return phoneNumberUtil.format(phoneNumber, PhoneNumberFormat.NATIONAL);
    }
}
