import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {collapseExpandEnterLeave} from '../_animations/collapseExpandEnterLeave';
import {RpcApiService} from '../_services/api/rpc-api.service';
import {Config} from '../_services/config';
import {AbstractRegistrationViewComponent} from './abstract-registration-view-component';

@Component({
    selector: 'registration-done-view',
    templateUrl: './registration-done-view.component.html',
    animations: [collapseExpandEnterLeave]
})
export class RegistrationDoneViewComponent extends AbstractRegistrationViewComponent implements OnInit {
    loading = true;
    success = false;

    supportPhoneLink: string;
    supportEmailLink: string;

    constructor(
        private translate: TranslateService,
        private rpcApiService: RpcApiService,
        private config: Config
    ) {
        super();
    }

    ngOnInit() {
        this.supportEmailLink = '<a href="mailto://' + this.config.get('support.email') + '">'
            + this.config.get('support.email')
            + '</a>';
        this.supportPhoneLink = '<a href="tel://' + this.config.get('support.phone').replace(/\D/g, '') + '">'
            + this.config.get('support.phone')
            + '</a>';
    }

    protected onViewActive() {
        this.loading = true;
        this.success = false;

        if (this.formData.submitted) {
            this.loading = false;
            this.success = true;
            return;
        }

        let body: any = this.formData;
        Object.keys(body).forEach(key => {
            if (body[key] === undefined || body[key] === null || body[key] === '') {
                delete body[key];
            }
        });

        this.rpcApiService
            .executeRequest('POST', 'registration', body)
            .then(() => {
                this.success = true;

                this.formData.submitted = true;
                this.formDataChange.emit(this.formData);
            })
            .catch(error => {
                console.error('Registration backend denied POST /registration', error);
            })
            .then(() => {
                this.loading = false;
            });
    }
}
