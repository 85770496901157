import {Injectable} from '@angular/core';
import {Config} from './config';

declare var gtag: Function;

@Injectable()
export class GoogleTagManagerService {
    trackingID: string;
    enabled: boolean;

    constructor(
        private config: Config,
    ) {
        this.trackingID = this.config.get("google.tag-manager.tracking-id");
        this.enabled = this.config.get("google.tag-manager.enabled");
    }

    /**
     * Push a page view to Google Tag Manager
     * @param {string} page
     */
    trackPageView(page: string) {
        if (!this.enabled || !this.config.get("google.tag-manager.tracking-id") || !gtag) {
            return;
        }

        gtag('config', this.trackingID, {
            'page_path': page
        });
    }
}
