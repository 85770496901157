import {Injectable, OnDestroy, ViewChild, ViewContainerRef} from '@angular/core';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';
import {Subscription} from 'rxjs';
import {SnackbarComponent} from './_components/snackbar.component';
import {SnackbarService} from './_services/snackbar.service';
import {TitleService} from './_services/title.service';

@Injectable()
export abstract class AbstractLayoutComponent implements OnDestroy {
    routeTitle: string;
    routeTitleSubscription: Subscription;
    snackbarSubscription: Subscription;
    @ViewChild('snackbarContainer', { read: ViewContainerRef, static: true }) snackbarContainer: ViewContainerRef;

    constructor(
        protected titleService: TitleService,
        protected snackbar: MatSnackBar,
        protected snackbarService: SnackbarService
    ) {
        this.routeTitleSubscription = titleService.routeTitleChanged$.subscribe(routeTitle => this.routeTitle = routeTitle);
        this.snackbarSubscription = snackbarService.newMessage$
            .subscribe(([type, message, duration]) => this.showSnackbar(type, message, duration));
    }

    private showSnackbar(type: string, message: string, duration: number) {
        let config = new MatSnackBarConfig();

        config.viewContainerRef = this.snackbarContainer;
        config.duration = duration;

        let snackbarRef = this.snackbar.openFromComponent(SnackbarComponent, config);
        snackbarRef.instance.type = type;
        snackbarRef.instance.message = message;
    }

    ngOnDestroy() {
        // prevent memory leak when component destroyed
        this.routeTitleSubscription.unsubscribe();
        this.snackbarSubscription.unsubscribe();
    }
}
