export class Locale {
    countryCode: string; // ISO 3166-2 country code, always uppercase
    languageCode: string; // ISO 639-2 language code, always lowercase

    constructor(countryCode: string, languageCode: string) {
        this.countryCode = countryCode.toUpperCase();
        this.languageCode = languageCode.toLowerCase();
    }

    static fromString(locale: string): Locale {
        let [languageCode, countryCode] = locale.split('-');
        return new Locale(countryCode, languageCode);
    }

    toString() {
        return this.languageCode + '-' + this.countryCode;
    }
}
