import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import * as moment from 'moment';
import 'moment-timezone';
import {distinctUntilChanged} from 'rxjs/operators';
import {brand} from '../environment';
import {Config} from './_services/config';
import {GoogleTagManagerService} from './_services/googleTagManagerService';
import {VERSION} from './version';

// This still has to be declared
declare var gtag: Function;

@Component({
    selector: 'app',
    templateUrl: './app.component.html'
})

export class AppComponent implements OnInit  {
    preloading = true;

    constructor(
        private router: Router,
        private translate: TranslateService,
        private config: Config,
        private googleTagManagerService: GoogleTagManagerService
    ) {}

    ngOnInit() {
        // Set supported languages and default language from config
        this.translate.addLangs(this.config.get('locales.supported'));
        // used when a translation isn't found in the current language
        this.translate.setDefaultLang('en-GB');

        // Use default language initially
        let localeToUse: string;
        const browserLocale = this.translate.getBrowserCultureLang();

        const defaultLocaleForLanguageMapping = { // needs to have an entry for every language of the supported locales
            'en': 'en-GB',
            'nl': 'nl-NL',
            'zh': 'zh-CN',
            'de': 'de-DE',
            'fr': 'fr-FR',
            'es': 'es-ES',
            'ca': 'ca-ES'
        };

        if (browserLocale && this.translate.getLangs().indexOf(browserLocale) >= 0) {
            localeToUse = browserLocale;
        }

        if (!localeToUse && defaultLocaleForLanguageMapping.hasOwnProperty(browserLocale)) {
            localeToUse = defaultLocaleForLanguageMapping[browserLocale];
        }

        if (!localeToUse) {
            localeToUse = this.config.get('locales.default')
        }

        this.translate.onLangChange.subscribe(() => {
            this.handleChangedLocale();
        });

        this.translate.use(localeToUse);

        // Set title from route on route changes
        this.router.events.pipe(distinctUntilChanged((previous: any, current: any) => {
            // Subscribe to any `NavigationEnd` events where the url has changed
            if (current instanceof NavigationEnd) {
                return previous.url === current.url;
            }
            return true;
        })).subscribe((x: any) => {
            this.googleTagManagerService.trackPageView(x.url);
        });

        this.preloading = false;

        console.info(
            '%cRegistration Web %cv'
            + VERSION + ' %cfor brand %c'
            + brand + '%c has been initialized',
            'color: #3cb9c4;',
            'color: #34e4f3;font-weight: bold',
            'color: #3cb9c4;font-weight: normal',
            'color: #34e4f3;font-weight: bold',
            'color: #3cb9c4;font-weight: normal'
        );
    }

    private handleChangedLocale() {
        const momentLocaleMapping = { // needs to have an entry for every supported locale
            'en-GB': ['en-gb', 'en'],
            'nl-NL': ['nl-nl', 'nl'],
            'zh-CN': ['zh-cn'],
            'zh-HK': ['zh-hk'],
            'de-DE': ['de-de', 'de'],
            'fr-FR': ['fr-fr', 'fr'],
            'es-ES': ['es'],
            'ca-ES': ['ca']
        };

        moment.locale(
            momentLocaleMapping.hasOwnProperty(this.translate.currentLang)
                ? momentLocaleMapping[this.translate.currentLang]
                : 'en-gb'
        );
    }
}
