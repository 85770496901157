import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable()
export class SnackbarService  {
    newMessage$ = new Subject<[string, string, number]>();

    info(message: string, duration: number = 5000) {
        this.addSnack('info', message, duration);
    }

    success(message: string, duration: number = 5000) {
        this.addSnack('success', message, duration);
    }

    error(message: string, duration: number = 5000) {
        this.addSnack('error', message, duration);
    }

    private addSnack(type: string, message: string, duration: number) {
        this.newMessage$.next([type, message, duration]);
    }
}
