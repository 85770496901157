import {Component} from '@angular/core';

@Component({
    selector: 'snackbar-component',
    templateUrl: './snackbar.component.html'
})
export class SnackbarComponent {
    public message: string;
    public type: string;
}
