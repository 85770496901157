import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {of as observableOf} from 'rxjs';
import {first, map} from 'rxjs/operators';

@Pipe({
    name: 'countryName'
})
export class CountryNamePipe implements PipeTransform {
    constructor(
        private translate: TranslateService
    ) {}

    transform(countryCode) {
        if (!countryCode) {
            return observableOf('');
        }
        return this.translate.get('COUNTRY.' + countryCode.toUpperCase()).pipe(
            first(),
            map(countryName => {
                return countryName ? countryName : countryCode;
            }));
    }
}
