import {Injectable} from '@angular/core';
import {HttpService} from './http.service';
import {Config} from './config';

@Injectable()
export class SystemService {
    constructor(
        public httpService: HttpService,
        private config: Config
    ) {}

    heartbeat(): Promise<boolean> {

        let clientId = this.config.get('monitorlinq.api.clientId'),
            authorizationHeader = this.httpService.getHeaders()['Authorization'],
            basePath = this.config.get("monitorlinq.api.apiUrl"),
            rpcApiEndPoint = this.config.get("monitorlinq.api.rpc.endPoint");

        this.httpService.updateHeader('Authorization', 'ClientId ' + clientId);

        return this.httpService.get(basePath + rpcApiEndPoint + 'heartbeat').toPromise()
            .then((response: any) => {
                return response.status && response.status === 'OK';
            })
            .catch(() => {
                // Something went wrong
                return false;
            })
            .then((ok) => {
                this.httpService.resetHeaders(true);
                if (authorizationHeader) {
                    this.httpService.updateHeader('Authorization', authorizationHeader.toString());
                }
                return ok;
            });
    }
}
