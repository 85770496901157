import {animate, state, style, transition, trigger} from '@angular/animations';

export const collapseExpand = trigger('collapseExpand', [
    state('0', style({
        height: 0,
        opacity: 0
    })),
    state('1', style({
        height: '*',
        opacity: 1
    })),
    transition('0 <=> 1', animate('300ms ease-in-out')),
]);
