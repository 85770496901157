import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule, Title} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {MomentModule} from 'ngx-moment';
import {ConnectionErrorDialogComponent} from './_components/connection-error-dialog.component';
import {ContentBlockComponent} from './_components/content-block.component';
import {DateFormGroupComponent} from './_components/date-form-group.component';
import {InputValidationMessagesComponent} from './_components/input-validation-messages';
import {LoadingBlockComponent} from './_components/loading-block';
import {LoadingBlockInDomComponent} from './_components/loading-block-in-dom';
import {LoadingIndicatorComponent} from './_components/loading-indicator/loading-indicator.component';
import {PhoneFormGroupComponent} from './_components/phone-form-group.component';
import {ServerErrorDialogComponent} from './_components/server-error-dialog.component';
import {SnackbarComponent} from './_components/snackbar.component';
import {IfFeatureFlagEnabledDirective} from './_directives/if-feature-flag-enabled.directive';
import {FeatureFlagGuard} from './_guards/feature-flag.guard';
import {ErrorHandlerInterceptor} from './_interceptors/error-handler.interceptor';
import {RavenErrorHandler} from './_interceptors/raven-error-handler';
import {BrandNamePipe} from './_pipes/brand-name.pipe';
import {CountryNamePipe} from './_pipes/country-name';
import {PhoneNumberPipe} from './_pipes/phone-number';
import {RpcApiService} from './_services/api/rpc-api.service';
import {apiConfigInitializer, Config} from './_services/config';
import {DialogService} from './_services/dialog.service';
import {FeatureFlagService} from './_services/feature-flag.service';
import {GoogleTagManagerService} from './_services/googleTagManagerService';
import {HttpService} from './_services/http.service';
import {SnackbarService} from './_services/snackbar.service';
import {SystemService} from './_services/system.service';
import {TitleService} from './_services/title.service';
import {PruningTranslationLoader} from './_util/pruning-translation-loader';
import {AppComponent} from './app.component';
import {routing} from './app.routing';
import {MaterialModule} from './modules/material.module';
import {NewCustomerRegistrationComponent} from './registration/new-customer-registration.component';
import {RegistrationBillingViewComponent} from './registration/registration-billing-view.component';
import {RegistrationCustomerViewComponent} from './registration/registration-customer-view.component';
import {RegistrationDoneViewComponent} from './registration/registration-done-view.component';
import {RegistrationDriverViewComponent} from './registration/registration-driver-view.component';
import {RegistrationIntroViewComponent} from './registration/registration-intro-view.component';
import {RegistrationOverviewViewComponent} from './registration/registration-overview-view.component';
import {RegistrationComponent} from './registration/registration.component';
import {VERSION} from './version';

// AoT requires an exported function for factories
export function PruningTranslationLoaderFactory(http: HttpClient) {
    // by default translation files will be loaded from /assets/i18n/[lang].json
    return new PruningTranslationLoader(http, './assets/i18n/', '.json?v=' + VERSION);
}

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        BrowserModule,
        BrowserAnimationsModule,
        MaterialModule,
        MomentModule,
        routing,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: PruningTranslationLoaderFactory,
                deps: [HttpClient]
            }
        })
    ],
    declarations: [
        AppComponent,
        LoadingIndicatorComponent,
        RegistrationComponent,
        NewCustomerRegistrationComponent,
        RegistrationIntroViewComponent,
        RegistrationCustomerViewComponent,
        RegistrationDriverViewComponent,
        RegistrationBillingViewComponent,
        RegistrationOverviewViewComponent,
        RegistrationDoneViewComponent,
        ContentBlockComponent,
        InputValidationMessagesComponent,
        PhoneFormGroupComponent,
        DateFormGroupComponent,
        LoadingBlockComponent,
        LoadingBlockInDomComponent,
        SnackbarComponent,
        ConnectionErrorDialogComponent,
        ServerErrorDialogComponent,
        // Pipes
        PhoneNumberPipe,
        CountryNamePipe,
        BrandNamePipe,
        // Directives
        IfFeatureFlagEnabledDirective,
    ],
    providers: [
        // Guards
        FeatureFlagGuard,
        // Services
        FeatureFlagService,
        RpcApiService,
        SystemService,
        TitleService,
        DialogService,
        SnackbarService,
        HttpService,
        GoogleTagManagerService,
        Config,
        Title,
        // Pipes
        PhoneNumberPipe,
        CountryNamePipe,
        BrandNamePipe,
        {
            provide: APP_INITIALIZER,
            useFactory: apiConfigInitializer,
            deps: [Config],
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorHandlerInterceptor,
            multi: true,
        },
        {
            provide: ErrorHandler,
            useClass: RavenErrorHandler,
            deps: [Config]
        }
    ],
    entryComponents: [
        ConnectionErrorDialogComponent,
        ServerErrorDialogComponent,
        SnackbarComponent,
    ],
    bootstrap: [
        AppComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
