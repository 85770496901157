import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {DateValidation} from '../_validation/date-validation';
import * as moment from 'moment';
import {Moment} from 'moment';

const momentJs = (moment as any).default ? (moment as any).default : moment;

@Component({
    selector: 'date-form-group',
    templateUrl: './date-form-group.html'
})
export class DateFormGroupComponent implements OnInit {
    @Input() showLabels = true;

    loading = true;
    minDate: Moment = momentJs().subtract(100, 'year');
    maxDate: Moment = momentJs().subtract(10, 'year');
    inputOrder: string[];
    inputLabels: {
        [inputName: string]: string
    };
    monthOptions: string[];
    formGroup: FormGroup = this.formBuilder.group(
        {
            day: [''],
            month: ['0'],
            year: [''],
        },
        {
            validator: DateValidation.betweenAndValid(this.minDate, this.maxDate, '[)')
        }
    );
    errorDefinitions: any = {};

    constructor(
        private formBuilder: FormBuilder,
        private translate: TranslateService
    ) {

    }

    ngOnInit() {
        this.translate.onLangChange.subscribe(() => {
            this.onLocaleChange();
        });

        if (this.showLabels && this.formGroup.get('month').value === '0') {
            this.formGroup.get('month').setValue('1');
        }

        this.onLocaleChange();
    }

    markAsDirty() {
        for (const formControlName of Object.keys(this.formGroup.controls)) {
            this.formGroup.get(formControlName).markAsDirty();
        }
    }

    isValid(): boolean {
        this.markAsDirty();
        return this.formGroup.valid;
    }

    getValue(): string {
        let formGroupValues = this.formGroup.value;

        return momentJs()
            .utc()
            .year(formGroupValues.year)
            .month(formGroupValues.month - 1)
            .date(formGroupValues.day)
            .hour(0)
            .minute(0)
            .second(0)
            .format('Y-M-D');
    }

    setValue(value: string) {
        if (!value) {
            return;
        }

        let date = momentJs(value, 'Y-M-D');

        this.formGroup.setValue({
            day: date.format('D'),
            month: date.format('M'),
            year: date.format('Y')
        });
    }

    private onLocaleChange() {
        // Determine what order to display the form groups in, based on the user's locale
        let localeDateFormat = momentJs.localeData().longDateFormat('L'),
            inputOrder = ['day', 'month', 'year'],
            positions = {
                day: localeDateFormat.indexOf('D'),
                month: localeDateFormat.indexOf('M'),
                year: localeDateFormat.indexOf('Y'),
            };

        inputOrder.sort((a, b) => {
            let positionA = positions[a],
                positionB = positions[b];
            if (positionA < positionB) {
                return -1;
            }
            if (positionA > positionB) {
                return 1;
            }

            return 0;
        });

        this.inputOrder = inputOrder;

        // Determine the localized month options
        this.monthOptions = momentJs.months();

        // Wait until translations have loaded
        this.translate.get(['FORM']).subscribe(() => {
            this.inputLabels = {
                day: this.translate.instant('FORM.DATE.DAY-LABEL'),
                month: this.translate.instant('FORM.DATE.MONTH-LABEL'),
                year: this.translate.instant('FORM.DATE.YEAR-LABEL')
            };

            this.errorDefinitions = {
                "required_day": this.translate.instant('FORM-ERROR.DATE.DAY-REQUIRED'),
                "required_month": this.translate.instant('FORM-ERROR.DATE.MONTH-REQUIRED'),
                "required_year": this.translate.instant('FORM-ERROR.DATE.YEAR-REQUIRED'),
                "invalid_day": this.translate.instant('FORM-ERROR.DATE.DAY-INVALID'),
                "invalid_month": this.translate.instant('FORM-ERROR.DATE.MONTH-INVALID'),
                "invalid_year": this.translate.instant('FORM-ERROR.DATE.YEAR-INVALID'),
                "between": this.translate.instant('FORM-ERROR.DATE.BIRTH-DATE-BETWEEN'),
            };
            this.loading = false;
        });
    }

    hasBetweenError(): boolean {
        return this.formGroup.get('day').errors
            && this.formGroup.get('day').errors.hasOwnProperty('between');
    }
}
