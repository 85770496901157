import {ComponentType} from '@angular/cdk/portal';
import {Injectable} from '@angular/core';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {ConnectionErrorDialogComponent} from '../_components/connection-error-dialog.component';
import {ServerErrorDialogComponent} from '../_components/server-error-dialog.component';

@Injectable()
export class DialogService {
    config: MatDialogConfig = new MatDialogConfig();
    connectionErrorDialog: MatDialogRef<ConnectionErrorDialogComponent>;
    serverErrorDialog: MatDialogRef<ServerErrorDialogComponent>;

    constructor(
        private dialog: MatDialog
    ) {
        this.config.panelClass = ['cdk-dialog-overlay'];
        this.config.maxWidth = '100vw';
        this.config.width = '100vw';
        this.config.maxHeight = '100vh';
        this.config.autoFocus = false;
    }

    public openConnectionErrorDialog() {
        if (this.connectionErrorDialog) {
            // Only allow one of this dialog to be open at all times
            return;
        }

        this.connectionErrorDialog = this.dialog.open(ConnectionErrorDialogComponent, {
            disableClose: true,
            backdropClass: 'error-dialog-backdrop',
            panelClass: ['cdk-dialog-overlay']
        });

        this.connectionErrorDialog.afterClosed().subscribe(() => {
            this.connectionErrorDialog = undefined;
        });
    }

    public openServerErrorDialog() {
        if (this.serverErrorDialog) {
            // Only allow one of this dialog to be open at all times
            return;
        }

        this.serverErrorDialog = this.dialog.open(ServerErrorDialogComponent, {
            disableClose: true,
            backdropClass: 'error-dialog-backdrop',
            panelClass: ['cdk-dialog-overlay']
        });

        this.serverErrorDialog.afterClosed().subscribe(() => {
            this.serverErrorDialog = undefined;
        });
    }

    openDialog<T>(component: ComponentType<T>, extraOptions: MatDialogConfig = {}): MatDialogRef<T> {
        return this.dialog.open(component, Object.assign({}, this.config, extraOptions));
    }

    closeAllDialogs() {
        this.dialog.closeAll();
    }
}
