import {Component, Input} from '@angular/core';

@Component({
    selector: 'input-validation-messages',
    templateUrl: './input-validation-messages.html'
})
export class InputValidationMessagesComponent {
    private _inputErrors: {[k: string]: any} = [];
    private _errorDefinitions: {[k: string]: any} = [];
    errorMessages: string[];

    @Input() displayIf = false;

    @Input()
    set inputErrors(inputErrors: { [k: string]: any }) {
        this._inputErrors = inputErrors || {};
        this.determineErrorMessages();
    }

    @Input()
    set errorDefinitions(errorDefinitions: { [k: string]: any }) {
        this._errorDefinitions = errorDefinitions || {};
        this.determineErrorMessages();
    }

    private determineErrorMessages() {
        this.errorMessages = [];

        for (const errorKey of Object.keys(this._inputErrors)) {
            // see if there is a definition for the error key
            // if not, we are dealing with an API error
            let errorMessage = this._errorDefinitions && this._errorDefinitions.hasOwnProperty(errorKey)
                ? this._errorDefinitions[errorKey]
                : this._inputErrors[errorKey];

            if (typeof errorMessage === 'boolean') {
                // This should not happen,
                // but display a fallback error message in case it's missing from the definitions
                errorMessage = 'Please correct your input for this field';
            }

            this.errorMessages.push(errorMessage);
        }
    }
}
