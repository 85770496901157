import {Pipe, PipeTransform} from '@angular/core';
import {Config} from '../_services/config';

@Pipe({
    name: 'brandName'
})
export class BrandNamePipe implements PipeTransform {
    constructor(
        private config: Config
    ) {}

    transform(value: string): string {
        if (value === null) {
            return this.config.get('brandName');
        }

        if (value.indexOf('{{brandName}}') >= 0) {
            return value.replace('{{brandName}}', this.config.get('brandName'));
        }

        return value;
    }
}
