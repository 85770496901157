import {Injectable} from '@angular/core';
import {Config} from './config';

@Injectable()
export class FeatureFlagService {
    private flags: any;

    constructor(
        private config: Config
    ) {
        this.flags = config.get('features');
    }

    isEnabled(featureName: string): boolean {
        if (!this.flags) {
            return false;
        }
        return Config.findProperty(this.flags, featureName, false) === true;
    }
}
