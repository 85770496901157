import {EventEmitter, Injectable, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import * as Sentry from '@sentry/browser';
import {RegistrationFormData} from '../_interfaces/registrationFormData';

@Injectable()
export abstract class AbstractRegistrationViewComponent implements OnChanges {
    @Input() viewActive = false;
    @Input() showPrevButton = false;

    @Output() onNextPage = new EventEmitter<RegistrationFormData>();
    @Output() onPrevPage = new EventEmitter<RegistrationFormData>();

    // Two way binding of form data to ensure the RegistrationComponent's form data is always updated
    @Input() formData: RegistrationFormData = {};
    @Output() formDataChange = new EventEmitter<RegistrationFormData>();

    ngOnChanges(changes: SimpleChanges) {
        if (changes['viewActive'] && this.viewActive) {
            this.onViewActive();
        }
    }

    goToNextPage() {
        Sentry.addBreadcrumb({
            category: 'NextRegistrationStep',
            data: this.formData,
            level: Sentry.Severity.Debug
        });

        this.formDataChange.emit(this.formData);
        this.onNextPage.emit(this.formData);
    }

    goToPrevPage() {
        this.formDataChange.emit(this.formData);
        this.onPrevPage.emit(this.formData);
    }

    protected onViewActive() {
        // Can be implemented by descendants
    }
}
