import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, ValidationErrors, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import * as IBAN from 'iban';
import {collapseExpandEnterLeave} from '../_animations/collapseExpandEnterLeave';
import {Config} from '../_services/config';
import {FeatureFlagService} from '../_services/feature-flag.service';
import {AbstractRegistrationViewComponent} from './abstract-registration-view-component';

@Component({
    selector: 'registration-billing-view',
    templateUrl: './registration-billing-view.component.html',
    animations: [collapseExpandEnterLeave]
})
export class RegistrationBillingViewComponent extends AbstractRegistrationViewComponent implements OnInit {
    errorDefinitions: any;

    billingPeriodProductSkuMapping: {
        [billingPeriod: string]: string
    } = {};
    billingPeriodSuffix: {
        [billingPeriod: string]: string
    } = {};
    bankAccountEnabled = true;

    form = this.formBuilder.group({
        billingPeriod: ['MONTHLY', Validators.required],
    });

    constructor(
        private formBuilder: FormBuilder,
        private translate: TranslateService,
        private config: Config,
        private featureFlagService: FeatureFlagService
    ) {
        super();
        this.billingPeriodProductSkuMapping = this.config.get('billingPeriodProductSkuMapping');
        this.billingPeriodSuffix = this.config.get('billingPeriodSuffix');
    }

    ngOnInit() {
        this.bankAccountEnabled = this.featureFlagService.isEnabled('main.billingBankAccount');
        if (this.bankAccountEnabled) {
            this.form.addControl(
                'bankAccountNumber',
                this.formBuilder.control('', Validators.compose([
                    Validators.required,
                    (control: AbstractControl): ValidationErrors | null => {
                        return this.bankAccountEnabled && !IBAN.isValid(control.value) ? {'invalid': true} : null;
                    }
                ]))
            );
            this.form.addControl(
                'bankAccountHolder', this.formBuilder.control('', Validators.required)
            );
            this.form.addControl(
                'acceptDirectDebit', this.formBuilder.control(false, Validators.requiredTrue)
            );
        }

        this.translate.onLangChange.subscribe(() => {
            this.onLocaleChange();
        });

        this.onLocaleChange();
    }

    private onLocaleChange() {
        this.errorDefinitions = {};
        this.translate.get('FORM-ERROR').subscribe(() => {
            this.errorDefinitions = {
                bankAccountNumber: {
                    "required": this.translate.instant('FORM-ERROR.IBAN.REQUIRED'),
                    "invalid": this.translate.instant('FORM-ERROR.IBAN.INVALID')
                },
                bankAccountHolder: {
                    "required": this.translate.instant('FORM-ERROR.BANK-ACCOUNT-HOLDER.REQUIRED')
                },
                acceptDirectDebit: {
                    "required": this.translate.instant('FORM-ERROR.ACCEPT-DIRECT-DEBIT.REQUIRED')
                },
            };
        });
    }

    protected onViewActive() {
        let values = {
            billingPeriod: this.mapProductSkuToBillingPeriod(this.formData.productSku) || 'MONTHLY',
        };

        if (this.bankAccountEnabled) {
            values['bankAccountNumber'] = this.formData.bankAccountNumber || '';
            values['bankAccountHolder'] = this.formData.bankAccountHolder || '';
            values['acceptDirectDebit'] = this.formData.acceptDirectDebit || false;
        }

        this.form.setValue(values);
    }

    onFormSubmit() {
        // mark all form controls as dirty to show possible error messages
        for (const formControlName of Object.keys(this.form.controls)) {
            this.form.get(formControlName).markAsDirty();
        }

        if (!this.form.valid) {
            return;
        }

        this.saveFormData();
        this.goToNextPage();
    }

    saveFormData() {
        if (this.bankAccountEnabled) {
            this.formData.bankAccountHolder = this.form.value.bankAccountHolder;
            this.formData.bankAccountNumber = this.form.value.bankAccountNumber;
            this.formData.acceptDirectDebit = this.form.value.acceptDirectDebit;
        }
        this.formData.productSku = this.mapBillingPeriodToProductSku(this.form.value.billingPeriod);
    }

    private mapBillingPeriodToProductSku(billingPeriod: string) {
        if (this.billingPeriodProductSkuMapping.hasOwnProperty(billingPeriod)) {
            return this.billingPeriodProductSkuMapping[billingPeriod];
        }

        return undefined;
    }

    private mapProductSkuToBillingPeriod(productSku: string) {
        const productSkuBillingPeriodMapping = Object
            .entries(this.billingPeriodProductSkuMapping)
            .reduce((o, [k, v]) => ({...o, [v]: k}), {});
        if (productSkuBillingPeriodMapping.hasOwnProperty(productSku)) {
            return productSkuBillingPeriodMapping[productSku];
        }

        return undefined;
    }
}
