import {Config} from '../config';

export class ApiErrorResponse {
    statusCode: number;
    message: string;
    errors: {
        [k: string]: {
            [k: string]: string
        };
    };

    constructor(statusCode: number, errors: { [p: string]: { [p: string]: string } } = {}, message?: string) {
        this.statusCode = statusCode;
        this.errors = errors;
        if (message) {
            this.message = message;
        }
    }
}

export class AbstractApiService {
    protected basePath: string;

    constructor(
        protected config: Config
    ) {
        this.basePath = this.applyCurrentHostToApiUrl(config.get("monitorlinq.api.apiUrl"));
    }

    protected applyCurrentHostToApiUrl(apiUrl: string) {
        return apiUrl.replace('{{host}}', window.location.protocol + '//' + window.location.host);
    }
}
