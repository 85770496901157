import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
    templateUrl: './server-error-dialog.component.html'
})
export class ServerErrorDialogComponent {

    constructor(
        public dialogRef: MatDialogRef<ServerErrorDialogComponent>,
    ) {}

    reload() {
        window.location.reload();
    }
}
