
// Do NOT update this file in source
// This file should only be changed on the server.
// It will override any default config settings from the loaded brand's environment config

export const LOCAL_CONFIG = {
    "sentry": {
        "enabled": true,
    }
};
